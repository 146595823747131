<template>
    <div class="easter-egg__channel hidden desktop:block" style="width: calc(var(--container-padding-x) + 193px - 75px)">
        <div class="easter-egg">
            small details matter to us

        </div>
    </div>
    <main class="flex flex-col items-center page-container page-container--padded pb-8 desktop:pb-16 flex-grow"
          data-v-698818eb>
        <!---->


        <section class="splash-region flex flex-col items-center" data-v-71a09f26 data-v-698818eb v-if="pageData!=null && pageData!=''">
            <div v-html="pageData.Description"></div>
        </section>


        <section class=" slider-container box-border angled-strip angled-strip--accented angled-strip--middle"
                 style="--color: var(--bryton-magenta-500); --accent-color: var(--bryton-yellow-500); --angle: 9deg; --accent-scale-factor: 1; --default-scale-factor: 1; --desktop-scale-factor: 1; --default-margin-y: 7.919222016226814%; --tablet-margin-y: 7.919222016226814%; --desktop-margin-y: 15.838444032453628%; --default-transform-origin-x: center; --desktop-transform-origin-x: center; --default-top-transform-origin-x: center; --desktop-top-transform-origin-x: center"
                 data-v-7b187159 :style="{
                '--color': 'var(--bryton-magenta-500)',
                '--accent-color': 'var(--bryton-yellow-500)',
                '--angle': '9deg',
            }">
            <div class="angled-strip__accent" data-v-7b187159></div>
            <div class="angled-strip__background" data-v-7b187159></div>
            <!-- Display the current item -->
            <div id="testimonials"
                 class="flex flex-col w-full mt-24 tablet:mt-32 desktop:mt-40 mb-4 tablet:mb-16 desktop:mb-32"
                 data-v-70148a70 data-v-698818eb>
                <h2 class="text-white text-xl tablet:text-3xl w-64 tablet:w-full ml-12 tablet:mx-auto tablet:p-0 mb-4 tablet:mb-8 desktop:mb-16 font-black"
                    style="text-align: left;" data-v-70148a70>
                    WHAT OUR CUSTOMERS SAY:
                </h2>
                <div class="testimonial__content cursor-pointer relative w-full flex flex-col justify-center bg-white px-8 tablet:px-12 desktop:px-32 py-8 tablet:py-6 text-center tablet:text-left leading-relaxed tablet:leading-loose"
                     data-v-70148a70>
                    <div class="content">
                        <p style="text-align: left;">{{ currentData.description }}</p>
                        <h2 style="text-align: left;">{{ currentData.usr }}</h2>
                    </div>



                </div>
                <div class="dots-container">
                    <span v-for="(item, index) in data" :key="index"
                          :class="['dot', { active: currentIndex === index }]" @click="currentIndex = index"></span>
                </div>
            </div>

        </section>

        <section v-if="pageData2!=null && pageData2!=''">
            <div v-html="pageData2.Description"></div>
        </section>

        <section class="w-full" data-v-698818eb>
            <div id="contact" class="w-full mt-24 desktop:mt-64" data-v-1dec9371 data-v-698818eb>
                <div class="text-center flex flex-col place-items-center place-content-center w-full"
                     style="height: 512px; display: none" data-v-1dec9371>
                    <div class="text-4xl text-bryton-green-500 font-black uppercase" data-v-1dec9371>
                        Thanks!

                    </div>
                    <div class="text-lg max-w-sm" data-v-1dec9371>
                        We got your message. Please keep an eye on your inbox for our response.

                    </div>
                </div>
                <div class="text-center flex flex-col place-items-center place-content-center w-full"
                     style="height: 512px; display: none" data-v-1dec9371>
                    <picture class="max-w-lg animate-working" data-v-1dec9371>
                        <source srcset="/_nuxt/image/be9d1d.webp" type="image/webp">
                        <img src="../assets/images/6304f0.png" width="96" height="96">
                    </picture>
                    <div class="text-2xl font-black uppercase" data-v-1dec9371>
                        Sending...

                    </div>
                </div>
                <div class="text-center flex flex-col place-items-center place-content-center w-full"
                     style="height: 512px; display: none" data-v-1dec9371>
                    <div class="text-4xl text-bryton-red-500 font-black uppercase" data-v-1dec9371>
                        Oops!

                    </div>
                    <div class="text-lg max-w-sm" data-v-1dec9371>
                        Looks like we're having trouble right now. Please try again later or...

                    </div>
                    <a href="mailto:Jeff@BrytonPrinting.com"
                       class="callout uppercase cursor-pointer text-2xl tablet:text-3xl font-black text-center flex mx-auto mt-6"
                       style="--text-color: #000; --reactive-text-color: #000" data-v-8e38860e data-v-1dec9371>
                        <div class="animated-underline"
                             style="--underline-color: var(--bryton-yellow-500); --default-underline-width: 33%; --tablet-underline-width: 33%; --desktop-underline-width: 33%; --default-underline-alignment: center; --tablet-underline-alignment: center; --desktop-underline-alignment: center; --default-alignment: center; --tablet-alignment: center; --desktop-alignment: center"
                             data-v-52d451c5 data-v-8e38860e>
                            E-Mail Us Instead

                        </div>
                    </a>
                </div>
                <div data-v-1dec9371>
                    <div data-v-1dec9371>
                        <h2 class="w-full text-left text-2xl tablet:text-3xl capitalize leading-none" data-v-1dec9371
                            data-v-698818eb>
                            ENOUGH ABOUT US,<br data-v-1dec9371 data-v-698818eb>
                            <strong class="font-black" data-v-1dec9371 data-v-698818eb>LET'S TALK ABOUT YOU.</strong>
                        </h2>
                    </div>

                    <form id="contactUsForm" runat="server" name="WebToLeads4695435000001702036" accept-charset="UTF-8"
                          class="" data-v-1dec9371>
                        <input name="xnQsjsdp" value="06f81ed146aa1570969f28a5f116b5fc1bac804c0f5469a562893bbc599dd081"
                               style="display: none" data-v-1dec9371>
                        <input id="zc_gad" type="hidden" name="zc_gad" data-v-1dec9371>
                        <input name="xmIwtLD" value="917746a04f8ebd0082d29a6fa9f219c1e12b93738752c89db0a079af15c6406d"
                               style="display: none" data-v-1dec9371>
                        <input name="actionType" value="TGVhZHM=" style="display: none" data-v-1dec9371>
                        <input name="returnURL" value="https://www.brytonprinting.com" style="display: none"
                               data-v-1dec9371>
                        <input name="Lead Source" value="Bryton Printing Website" style="display: none" data-v-1dec9371>

                        <div class="form-inputs">
                            <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required"
                                 data-v-8cd163c6 data-v-1dec9371>
                                <label for="First_Name" class="sr-only" data-v-8cd163c6>
                                    FIRST NAME
                                </label> <input id="First_Name" v-model="formData.firstName" placeholder="FIRST NAME"
                                                autocomplete="given-name" name="First Name" maxlength="40" required
                                                class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full"
                                                data-v-8cd163c6>
                                <span v-if="errors.firstName" class="text-danger">{{ errors.firstName }}</span>
                            </div>
                            <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required"
                                 data-v-8cd163c6 data-v-1dec9371>
                                <label for="Last_Name" class="sr-only" data-v-8cd163c6>
                                    LAST NAME
                                </label> <input id="Last_Name" v-model="formData.lastName" placeholder="LAST NAME"
                                                autocomplete="family-name" name="Last Name" maxlength="80" required
                                                class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full"
                                                data-v-8cd163c6>
                                <span v-if="errors.lastName" class="text-danger">{{ errors.lastName }}</span>
                            </div>
                            <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required"
                                 data-v-8cd163c6 data-v-1dec9371>
                                <label for="Company" class="sr-only" data-v-8cd163c6>
                                    BUSINESS NAME
                                </label> <input id="Company" v-model="formData.businessName" placeholder="BUSINESS NAME"
                                                autocomplete="organization" name="Company" maxlength="100" required
                                                class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full"
                                                data-v-8cd163c6>
                                <span v-if="errors.businessName" class="text-danger">{{ errors.businessName }}</span>
                            </div>
                            <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required"
                                 data-v-8cd163c6 data-v-1dec9371>
                                <label for="Email" class="sr-only" data-v-8cd163c6>
                                    EMAIL
                                </label> <input id="Email" v-model="formData.email" placeholder="EMAIL"
                                                autocomplete="email" name="Email" maxlength="100" required
                                                class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full"
                                                data-v-8cd163c6>
                                <span v-if="errors.email" class="text-danger">{{ errors.email }}</span>
                            </div>
                        </div>
                        <div class="text-area box-content bg-bryton-grey-500 pl-4 pt-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 contact-form__field--tall col-span-full text-area--required"
                             data-v-082907f1 data-v-1dec9371>
                            <label for="Description" class="sr-only" data-v-082907f1>
                                Message
                            </label> <textarea id="Description" v-model="formData.message"
                                               placeholder="HOW CAN WE HELP?" name="LEADCF12" max-length="1024" required
                                               class="text-area__field placeholder-gray-500 outline-none bg-transparent w-full"
                                               data-v-082907f1></textarea>
                            <span v-if="errors.message" class="text-danger">{{ errors.message }}</span>
                        </div>
                        <div class="col-sm-6 col-xs-6">
                            <div class="form-group mb-3" :class="{ '': !isCapchaValid }">
                                <div id="recaptcha-ReviewForm" class="g-recaptcha" :data-sitekey="siteKey"></div>
                            </div>
                            <span v-if="errors.captcha" class="text-danger">{{ errors.captcha }}</span>

                        </div>
                        <div class="flex flex-row col-span-full" data-v-1dec9371>
                            <div class="text-bryton-red-500" style="display:none" data-v-1dec9371>
                                Sorry, something went wrong. Please try again.
                            </div>
                            <button @click="handleSubmit" type="button"
                                    class="callout uppercase cursor-pointer text-2xl tablet:text-3xl font-black ml-auto font-extrabold text-base leading-none"
                                    style="--text-color:#000;--reactive-text-color:#000" data-v-8e38860e data-v-1dec9371>
                                <div class="animated-underline animated-underline--right"
                                     style="--underline-color:var(--bryton-yellow-500);--default-underline-width:33%;--tablet-underline-width:33%;--desktop-underline-width:33%;--default-underline-alignment:right;--tablet-underline-alignment:right;--desktop-underline-alignment:right;--default-alignment:flex-end;--tablet-alignment:flex-end;--desktop-alignment:flex-end"
                                     data-v-52d451c5 data-v-8e38860e>
                                    SEND
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
    // import Service from '../Services.AdminService.js';
    //import AdminService from '../Services/AdminService.js';
    import AdminService from '../Services/AdminService'

    import axios from 'axios';

    export default {
        data() {
            return {
                currentIndex: 0,
                data: [
                    // Data for 5 Do's
                    { usr: "Elliott, SYSCO", description: "I had a rush job and a deadline to meet at work to produce 2000 booklets and distribute ASAP. Jeff was outstanding in reviewing my submission to him of the documents and making suggestions for type of paper and binding. Each booklet was over 100 pages with some tear out receipts that employees had to sign after we completed the training. I was highly pleased with the speed in which my booklets were produced, the quality and liked the fact that I had a proof to approve and still met my work deadline. Without hesitation I recommend Bryton Printing. I got to trust Jeff the owner who I felt charged us a fair price and felt I dealt with a honest printer." },
                    { usr: "Debbie Z., Anaheim, CA", description: "binding. Each booklet was over 100 pages with some tear out receipts that employees had to sign after we completed the training. I was highly pleased with the speed in which my booklets were produced, the quality and liked the fact that I had a proof to approve and still met my work deadline. Without hesitation I recommend Bryton Printing. I got to trust Jeff the owner who I felt charged us a fair price and felt I dealt with a honest printer.Elliott, SYSCOBryton Printing is top notch quality. Jeff is hard working and makes sure your order comes out correctly. His expertise in the quality of work produced by their staff is well worth checking out." },
                    { usr: "Coril P., Pomona, CA", description: " Bryton Printing is top notch quality. Jeff is hard working and makes sure your order comes out correctly. His expertise in the quality of work produced by their staff is well worth checking out." },
                    { usr: "Ed Collins, President", description: " I have known and worked with Jeff for over 30 years. I highly recommend him & Bryton for any printing and graphics requirements. Jeff is honest, fair, professional and a pleasure to work with." },
                    { usr: "Kevin Bryans, Country Manager, Indonesia, at Detmold Packaging", description: " I wanted to take a minute to write a testimonial about you, your staff, and your business. As you may know, you have now been providing my company with printing for ten years. During this time you have printed well over 1,000 jobs for us and have proven to be a valuable asset and ally to what we do. It is rare to find a business, especially a printer, that delivers a high level of quality and customer service with a competitive, low price. Usually one of those three important components suffers, but you have been able to maintain them all. And you and your staff go beyond the normal level of quality and service to often exceed my expectations. Here is a short list of what I am grateful for that you provide:It is a joy to work with you and your staff. Your efforts provide peace of mindto know we have a team that cares about our work as much as we do. So thank you,and your staff, for all you do. Know that I appreciate you all. You stand out as EXCELLENCE in a world of much mediocrity. I hope this helps communicate mysatisfaction and gratitude, and that you can share this with others so that they  may have the pleasure of working with you as well. (For the record, for anyone reading this, I was not asked or solicited in any way to write this letter. I am simply doing it because of my gratitude and high level of satisfaction." }
                ],
                siteKey: '6LfPmGQnAAAAAKF_W3hIf1iiQ33tbLRw4qmKYdBO',
                isCapchaValid: false,
                captchaTimeout: null,
                formData: {
                    name: '',
                    companyName: '',
                    message: '',
                    email: '',
                    phoneNo: ''
                },
                selectedFiles: [],
                pageData: null,
                pageData2: null,
                errors: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    businessName: '',
                    message: ''
                },
                progress: 0,
                isEmailValidate: false
            };
        },
        async created() {
            await this.GetEmailValidation();
            await this.fetchPageContent('HomeSection1');
            await this.fetchPageContent2('HomeSection2');
            //  this.renderRecaptcha();
        },

        // mounted() {
        //     if (!window.recaptchaRendered) {
        //       this.renderRecaptcha();
        //       window.recaptchaRendered = true;
        //       this.loadRecaptchaScript();
        //     }
        //   },

        mounted() {
            if (!window.recaptchaRendered) {
                this.loadRecaptchaScript(); // Load the script first
            } else {
                this.renderRecaptcha(); // Render only if the script is already loaded
            }
        },

        computed: {
            progressBarWidth() {
                return `${this.progress}%`;
            },
            currentData() {
                return this.data[this.currentIndex];
            },
        },
        methods: {
            async fetchPageContent(pageURL) {
                this.loading = true;
                this.pageData = null;
                // this.setPageURL(pageURL);

                try {
                    const response = await AdminService.getPageContent(pageURL);
                    this.pageData = response.data[0] || null;
                    console.log("HomePage",this.pageData.Description);
                } catch (error) {
                    console.error('Error fetching page content:', error);
                    this.pageData = null;
                } finally {
                    this.loading = false;
                }
            },
            async fetchPageContent2(pageURL) {
                this.loading = true;
                this.pageData2 = null;
                // this.setPageURL(pageURL);

                try {
                    const response = await AdminService.getPageContent(pageURL);
                    this.pageData2 = response.data[0] || null;
                   // console.log("HomePage", this.pageData.Description);
                } catch (error) {
                    console.error('Error fetching page content:', error);
                    this.pageData2 = null;
                } finally {
                    this.loading = false;
                }
            },
            // async handleSubmit() {
            //      // eslint-disable-next-line
            //    debugger;
            //             this.isLoading = true;
            //             await AdminService.handleSubmit(this.formData).then(response => {

            //                 this.ddlGroup = response.data;
            //                 this.isLoading = false;
            //                 this.$router.push('/Contact/Thankyou');
            //                 // this.formSubmitted = true;
            //             }).catch((error) => {
            //                 // this.$toast.error(this.$t('Toaster.ContactAdmin'));
            //                 this.error = true;
            //              this.errorMessage = error.message;
            //                 console.log(error);
            //             });
            //         },
            async handleSubmit() {
                // eslint-disable-next-line
                debugger;
                this.sending = true;
                this.error = false;
                this.errorMessage = '';
                let isFormValid = this.validateForm();
                if (isFormValid) {
                    try {
                        //   const response = await axios.post('https://localhost:7226/api/ContactForm/SendContact', this.formData, {
                        const response = await axios.post('https://node.brytonprinting.com/api/ContactForm/SendContact', this.formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        })
                        console.log(response);
                        this.$router.push('/Contact/Thankyou');
                        this.formSubmitted = true;
                        console.log('Form submitted successfully:', this.formData);
                    } catch (err) {
                        this.error = true;
                        this.errorMessage = err.message;
                        console.error('Error submitting form:', err);
                    } finally {
                        this.sending = false;
                    }
                }

            },
            loadRecaptchaScript() {
                const script = document.createElement('script');
                script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit`;
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);

                // Define a global callback function
                window.onRecaptchaLoad = () => {
                    this.renderRecaptcha();
                };
            },
            renderRecaptcha() {
                if (window.grecaptcha) {
                    const existingWidgetId = document.getElementById('recaptcha-ReviewForm').getAttribute('data-widget-id');

                    // Check if reCAPTCHA has already been rendered
                    if (existingWidgetId) {
                        window.grecaptcha.reset(existingWidgetId); // Reset the existing reCAPTCHA
                    } else {
                        const widgetId = window.grecaptcha.render('recaptcha-ReviewForm', {
                            sitekey: this.siteKey,
                            callback: this.onRecaptchaSuccess,
                            'expired-callback': this.onRecaptchaExpired,
                        });

                        // Store the widget ID in the element for later reference
                        document.getElementById('recaptcha-ReviewForm').setAttribute('data-widget-id', widgetId);
                    }
                }
            },
            onRecaptchaSuccess(response) {
                this.isCapchaValid = true;
                if (this.captchaTimeout) {
                    clearTimeout(this.captchaTimeout);
                }

                console.log(response);
                this.captchaTimeout = setTimeout(() => {
                    this.captchaValid = false;
                    //alert('CAPTCHA expired, please complete it again.');
                }, 5 * 60 * 1000);
            },
            onRecaptchaExpired() {
                this.isCapchaValid = false;
                this.errors.captcha = 'CAPTCHA expired. Please try again.';
            },
            beforeDestroy() {
                // Clear the timeout when the component is destroyed
                if (this.captchaTimeout) {
                    clearTimeout(this.captchaTimeout);
                }
            },

            validateForm() {
                this.errors = {};
                let isValid = true;

                // Validate email if isEmailValidate is true
                if (this.isEmailValidate) {
                    if (!this.formData.email) {
                        this.errors.email = 'Email is required.';
                        isValid = false;
                    } else if (!this.isValidEmail(this.formData.email)) {
                        this.errors.email = 'Invalid email format.';
                        isValid = false;
                    }
                }


                // Validate first name
                if (!this.formData.firstName) {
                    this.errors.firstName = 'First Name is required.';
                    isValid = false;
                }

                // Validate last name
                if (!this.formData.lastName) {
                    this.errors.lastName = 'Last Name is required.';
                    isValid = false;
                }

                // Validate business name
                if (!this.formData.businessName) {
                    this.errors.businessName = 'Business Name is required.';
                    isValid = false;
                }

                // Validate message
                if (!this.formData.message) {
                    this.errors.message = 'Message is required.';
                    isValid = false;
                }

                return isValid;
            },

            isValidEmail(email) {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return re.test(email);
            },
            isValidPhone(phoneNo) {
                const re = /^[0-9]{10}$/;
                return re.test(phoneNo);
            },

            // uploadForm(formData) {
            //   // eslint-disable-next-line
            //   debugger;
            //   axios.post('https://node.brytonprinting.com/api/ContactForm/PostContactForm', formData, {
            //     headers: {
            //       'Content-Type': 'multipart/form-data'
            //     },
            //     onUploadProgress: (progressEvent) => {
            //       this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //     }
            //   })
            //     .then(response => {
            //       this.$router.push('/Upload/ThankYou');
            //       console.log('Form submitted, response received:', response);
            //       if (response.data && response.data.contactId) {
            //         const contactId = response.data.contactId;
            //         console.log('Received Contact ID:', contactId);
            //       }

            //       this.resetForm();
            //     })
            //     .catch(error => {
            //       console.error('Error submitting form:', error);
            //       if (error.response && error.response.data && error.response.data.errors) {
            //         this.errors = error.response.data.errors;
            //       }
            //     });
            // },
            handleDrop(event) {
                const files = event.dataTransfer.files;
                this.addFiles(files);
            },
            // handleFileSelection(event) {
            //   const files = event.target.files;
            //   this.addFiles(files);
            // },
            handleFileSelection(event) {
                const files = event.target.files;
                const maxSize = 2 * 1024 * 1024 * 1024; // 2 GB in bytes

                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > maxSize) {
                        this.errors.files = 'File size exceeds the 2 GB limit.';
                        return;
                    }
                    this.selectedFiles.push(files[i]);
                }
            },
            addFiles(files) {
                // eslint-disable-next-line
                debugger;
                for (let i = 0; i < files.length; i++) {
                    this.selectedFiles.push(files[i]);
                }
                console.log('Files added:', this.selectedFiles);
            },
            removeFile(index) {
                this.selectedFiles.splice(index, 1);
                console.log('File removed:', index);
            },
            browseFiles() {
                this.$refs.fileInput.click();
            },
            resetForm() {
                this.formData = {
                    name: '',
                    companyName: '',
                    message: '',
                    email: '',
                    phoneNo: ''
                };
                this.selectedFiles = [];
                this.errors = {};
                console.log('Form reset');
            },
            async GetEmailValidation() {
                // eslint-disable-next-line
                debugger;

                try {
                    const response = await axios.get(`https://node.brytonprinting.com/api/ContactForm/GetEmailValidation`, {
                        //  const response = await axios.get(`https://localhost:7226/api/ContactForm/GetEmailValidation`, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });

                    // Check HTTP status code correctly
                    if (response.status === 200) {
                        // eslint-disable-next-line
                        debugger;
                        this.isEmailValidate = response.data[0].email;
                        localStorage.setItem("userType", this.loginModel.username);
                        this.$router.push('/UploadFileListing');
                    } else {
                        this.errorMessage = 'Invalid username or password.';
                    }
                } catch (error) {
                    this.errorMessage = 'An error occurred during login. Please try again.';
                }
            }
        }
    };
</script>
<style>
    .slider-container {
        padding: 20px;
        border: 1px solid var(--accent-color);
        position: relative;
        background: var(--color);
        border-radius: 12px;
        text-align: center;
    }

    .content {
        margin-bottom: 20px;
    }

    .dots-container {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .dot {
        width: 10px;
        height: 10px;
        background-color: var(--accent-color);
        border-radius: 50%;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
    }

        .dot.active {
            transform: scale(1.5);
            background-color: var(--color);
        }

    h2.w-full.text-left.text-2xl.tablet\:text-3xl.capitalize.leading-none {
        margin-bottom: 30px;
    }

    div#recaptcha-ReviewForm {
        margin-top: 19px;
    }

    .form-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text-input.bg-bryton-grey-500.px-4.py-3.text-sm.border.border-gray-600.tablet\:border-gray-300.hover\:border-gray-600.text-input--required {
        width: 250px;
        padding: 0px !important;
    }

    body {
        margin: 0
    }

    .scrollable-container {
        width: 100%;
        /* Adjust as needed */
        overflow-x: auto;
        /* Enable horizontal scrolling */
        white-space: nowrap;
        /* Prevent content from wrapping */
        border: 1px solid #ddd;
    }

    .content {
        display: inline-block;
        /* Ensure the content is displayed in a single line */
        /* Add styles for your content here */
    }

    main {
        display: block
    }

    h1 {
        font-size: 2em;
        margin: .67em 0
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible
    }

    pre {
        font-family: monospace, monospace;
        font-size: 1em
    }

    a {
        background-color: transparent
    }

    abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        text-decoration: underline dotted
    }

    b,
    strong {
        font-weight: bolder
    }

    code,
    kbd,
    samp {
        font-family: monospace, monospace;
        font-size: 1em
    }

    small {
        font-size: 80%
    }

    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline
    }

    sub {
        bottom: -.25em
    }

    sup {
        top: -.5em
    }

    img {
        border-style: none
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0
    }

    button,
    input {
        overflow: visible
    }

    button,
    select {
        text-transform: none
    }

    [type=button],
    [type=submit],
    button {
        -webkit-appearance: button
    }

        [type=button]::-moz-focus-inner,
        [type=submit]::-moz-focus-inner,
        button::-moz-focus-inner {
            border-style: none;
            padding: 0
        }

        [type=button]:-moz-focusring,
        [type=submit]:-moz-focusring,
        button:-moz-focusring {
            outline: 1px dotted ButtonText
        }

    fieldset {
        padding: .35em .75em .625em
    }

    legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal
    }

    progress {
        vertical-align: baseline
    }

    textarea {
        overflow: auto
    }

    [type=checkbox] {
        box-sizing: border-box;
        padding: 0
    }

    [type=number]::-webkit-inner-spin-button,
    [type=number]::-webkit-outer-spin-button {
        height: auto
    }

    details {
        display: block
    }

    summary {
        display: list-item
    }

    template {
        display: none
    }

    [hidden] {
        display: none
    }

    blockquote,
    dd,
    dl,
    figure,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    p,
    pre {
        margin: 0
    }

    button {
        background-color: transparent;
        background-image: none
    }

        button:focus {
            outline: 1px dotted;
            outline: 5px auto -webkit-focus-ring-color
        }

    fieldset {
        margin: 0;
        padding: 0
    }

    ol,
    ul {
        list-style: none;
        margin: 0;
        padding: 0
    }

    html {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        line-height: 1.5
    }

    *,
    ::after,
    ::before {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
        border-color: #e2e8f0
    }

    hr {
        border-top-width: 1px
    }

    img {
        border-style: solid
    }

    textarea {
        resize: vertical
    }

        input::placeholder,
        textarea::placeholder {
            color: #a0aec0
        }

    [role=button],
    button {
        cursor: pointer
    }

    table {
        border-collapse: collapse
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: inherit
    }

    a {
        color: inherit;
        text-decoration: inherit
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        padding: 0;
        line-height: inherit;
        color: inherit
    }

    code,
    kbd,
    pre,
    samp {
        font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
    }

    audio,
    canvas,
    embed,
    iframe,
    img,
    object,
    svg,
    video {
        display: block;
        vertical-align: middle
    }

    img,
    video {
        max-width: 100%;
        height: auto
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0
    }

    .bg-transparent {
        background-color: transparent
    }

    .bg-white {
        --bg-opacity: 1;
        background-color: #fff;
        background-color: rgba(255, 255, 255, var(--bg-opacity))
    }

    .bg-gray-800 {
        --bg-opacity: 1;
        background-color: #2d3748;
        background-color: rgba(45, 55, 72, var(--bg-opacity))
    }

    .bg-blue-100 {
        --bg-opacity: 1;
        background-color: #ebf8ff;
        background-color: rgba(235, 248, 255, var(--bg-opacity))
    }

    .bg-bryton-purple-500 {
        --bg-opacity: 1;
        background-color: #644a99;
        background-color: rgba(100, 74, 153, var(--bg-opacity))
    }

    .bg-bryton-magenta-500 {
        --bg-opacity: 1;
        background-color: #b53480;
        background-color: rgba(181, 52, 128, var(--bg-opacity))
    }

    .bg-bryton-red-500 {
        --bg-opacity: 1;
        background-color: #e83d3d;
        background-color: rgba(232, 61, 61, var(--bg-opacity))
    }

    .bg-bryton-yellow-500 {
        --bg-opacity: 1;
        background-color: #f7c522;
        background-color: rgba(247, 197, 34, var(--bg-opacity))
    }

    .bg-bryton-cyan-500 {
        --bg-opacity: 1;
        background-color: #58cdce;
        background-color: rgba(88, 205, 206, var(--bg-opacity))
    }

    .bg-bryton-grey-500 {
        --bg-opacity: 1;
        background-color: #e8e8e8;
        background-color: rgba(232, 232, 232, var(--bg-opacity))
    }

    .bg-opacity-75 {
        --bg-opacity: 0.75
    }

    .border-black {
        --border-opacity: 1;
        border-color: #000;
        border-color: rgba(0, 0, 0, var(--border-opacity))
    }

    .border-white {
        --border-opacity: 1;
        border-color: #fff;
        border-color: rgba(255, 255, 255, var(--border-opacity))
    }

    .border-gray-600 {
        --border-opacity: 1;
        border-color: #718096;
        border-color: rgba(113, 128, 150, var(--border-opacity))
    }

    .border-gray-700 {
        --border-opacity: 1;
        border-color: #4a5568;
        border-color: rgba(74, 85, 104, var(--border-opacity))
    }

    .border-bryton-grey-500 {
        --border-opacity: 1;
        border-color: #e8e8e8;
        border-color: rgba(232, 232, 232, var(--border-opacity))
    }

    .hover\:border-gray-600:hover {
        --border-opacity: 1;
        border-color: #718096;
        border-color: rgba(113, 128, 150, var(--border-opacity))
    }

    .rounded-full {
        border-radius: 9999px
    }

    .rounded-bl-full {
        border-bottom-left-radius: 9999px
    }

    .border-solid {
        border-style: solid
    }

    .border-0 {
        border-width: 0
    }

    .border-2 {
        border-width: 2px
    }

    .border {
        border-width: 1px
    }

    .border-t-2 {
        border-top-width: 2px
    }

    .border-b-2 {
        border-bottom-width: 2px
    }

    .border-b {
        border-bottom-width: 1px
    }

    .last\:border-b-0:last-child {
        border-bottom-width: 0
    }

    .box-border {
        box-sizing: border-box
    }

    .box-content {
        box-sizing: content-box
    }

    .cursor-default {
        cursor: default
    }

    .cursor-pointer {
        cursor: pointer
    }

    .block {
        display: block
    }

    .inline-block {
        display: inline-block
    }

    .flex {
        display: flex
    }

    .table {
        display: table
    }

    .grid {
        display: grid
    }

    .hidden {
        display: none
    }

    .flex-row {
        flex-direction: row
    }

    .flex-col {
        flex-direction: column
    }

    .flex-wrap {
        flex-wrap: wrap
    }

    .place-items-center {
        place-items: center
    }

    .place-content-center {
        place-content: center
    }

    .items-start {
        align-items: flex-start
    }

    .items-center {
        align-items: center
    }

    .content-between {
        align-content: space-between
    }

    .content-around {
        align-content: space-around
    }

    .self-end {
        align-self: flex-end
    }

    .self-center {
        align-self: center
    }

    .justify-center {
        justify-content: center
    }

    .justify-between {
        justify-content: space-between
    }

    .justify-around {
        justify-content: space-around
    }

    .justify-evenly {
        justify-content: space-evenly
    }

    .justify-self-end {
        justify-self: end
    }

    .justify-self-center {
        justify-self: center
    }

    .flex-grow {
        flex-grow: 1
    }

    .font-normal {
        font-weight: 400
    }

    .font-medium {
        font-weight: 500
    }

    .font-semibold {
        font-weight: 600
    }

    .font-bold {
        font-weight: 700
    }

    .font-extrabold {
        font-weight: 800
    }

    .font-black {
        font-weight: 900
    }

    .h-4 {
        height: 1rem
    }

    .h-6 {
        height: 1.5rem
    }

    .h-8 {
        height: 2rem
    }

    .h-12 {
        height: 3rem
    }

    .h-auto {
        height: auto
    }

    .h-full {
        height: 100%
    }

    .text-xs {
        font-size: .75rem
    }

    .text-sm {
        font-size: .875rem
    }

    .text-base {
        font-size: 1rem
    }

    .text-lg {
        font-size: 1.125rem
    }

    .text-xl {
        font-size: 1.25rem
    }

    .text-2xl {
        font-size: 1.5rem
    }

    .text-3xl {
        font-size: 1.875rem
    }

    .text-4xl {
        font-size: 2.25rem
    }

    .text-5xl {
        font-size: 2.7rem
    }

    .text-6xl {
        font-size: 3.7rem
    }

    .text-7xl {
        font-size: 4.9rem
    }

    .leading-none {
        line-height: 1
    }

    .leading-tight {
        line-height: 1.25
    }

    .leading-snug {
        line-height: 1.375
    }

    .leading-normal {
        line-height: 1.5
    }

    .leading-relaxed {
        line-height: 1.625
    }

    .leading-loose {
        line-height: 2
    }

    .leading-tightest {
        line-height: 1.15
    }

    .list-none {
        list-style-type: none
    }

    .m-auto {
        margin: auto
    }

    .mx-0 {
        margin-left: 0;
        margin-right: 0
    }

    .mx-2 {
        margin-left: .5rem;
        margin-right: .5rem
    }

    .my-4 {
        margin-top: 1rem;
        margin-bottom: 1rem
    }

    .mx-4 {
        margin-left: 1rem;
        margin-right: 1rem
    }

    .my-5 {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem
    }

    .my-6 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem
    }

    .mx-6 {
        margin-left: 1.5rem;
        margin-right: 1.5rem
    }

    .my-8 {
        margin-top: 2rem;
        margin-bottom: 2rem
    }

    .mx-8 {
        margin-left: 2rem;
        margin-right: 2rem
    }

    .my-24 {
        margin-top: 6rem;
        margin-bottom: 6rem
    }

    .mx-auto {
        margin-left: auto;
        margin-right: auto
    }

    .mr-0 {
        margin-right: 0
    }

    .mb-0 {
        margin-bottom: 0
    }

    .ml-0 {
        margin-left: 0
    }

    .mr-1 {
        margin-right: .25rem
    }

    .mb-2 {
        margin-bottom: .5rem
    }

    .ml-2 {
        margin-left: .5rem
    }

    .mt-3 {
        margin-top: .75rem
    }

    .mb-3 {
        margin-bottom: .75rem
    }

    .mt-4 {
        margin-top: 1rem
    }

    .mb-4 {
        margin-bottom: 1rem
    }

    .ml-4 {
        margin-left: 1rem
    }

    .mt-6 {
        margin-top: 1.5rem
    }

    .mb-6 {
        margin-bottom: 1.5rem
    }

    .mb-8 {
        margin-bottom: 2rem
    }

    .mb-10 {
        margin-bottom: 2.5rem
    }

    .mr-12 {
        margin-right: 3rem
    }

    .mb-12 {
        margin-bottom: 3rem
    }

    .ml-12 {
        margin-left: 3rem
    }

    .mt-16 {
        margin-top: 4rem
    }

    .mb-16 {
        margin-bottom: 4rem
    }

    .mt-24 {
        margin-top: 6rem
    }

    .mb-24 {
        margin-bottom: 6rem
    }

    .mt-32 {
        margin-top: 8rem
    }

    .mr-auto {
        margin-right: auto
    }

    .ml-auto {
        margin-left: auto
    }

    .max-w-sm {
        max-width: 24rem
    }

    .max-w-lg {
        max-width: 32rem
    }

    .max-w-4xl {
        max-width: 56rem
    }

    .max-w-full {
        max-width: 100%
    }

    .min-h-screen {
        min-height: 100vh
    }

    .object-contain {
        object-fit: contain
    }

    .object-cover {
        object-fit: cover
    }

    .outline-none {
        outline: 2px solid transparent;
        outline-offset: 2px
    }

    .overflow-hidden {
        overflow: hidden
    }

    .overflow-visible {
        overflow: visible
    }

    .overflow-y-auto {
        overflow-y: auto
    }

    .overflow-x-scroll {
        overflow-x: scroll
    }

    .py-1 {
        padding-top: .25rem;
        padding-bottom: .25rem
    }

    .py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .py-3 {
        padding-top: .75rem;
        padding-bottom: .75rem
    }

    .py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .px-4 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem
    }

    .px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .py-12 {
        padding-top: 3rem;
        padding-bottom: 3rem
    }

    .py-24 {
        padding-top: 6rem;
        padding-bottom: 6rem
    }

    .pt-0 {
        padding-top: 0
    }

    .pt-2 {
        padding-top: .5rem
    }

    .pr-2 {
        padding-right: .5rem
    }

    .pb-2 {
        padding-bottom: .5rem
    }

    .pt-3 {
        padding-top: .75rem
    }

    .pl-3 {
        padding-left: .75rem
    }

    .pb-4 {
        padding-bottom: 1rem
    }

    .pl-4 {
        padding-left: 1rem
    }

    .pl-6 {
        padding-left: 1.5rem
    }

    .pt-8 {
        padding-top: 2rem
    }

    .pb-8 {
        padding-bottom: 2rem
    }

    .pl-8 {
        padding-left: 2rem
    }

    .pt-20 {
        padding-top: 5rem
    }

    .pt-24 {
        padding-top: 6rem
    }

    .pb-24 {
        padding-bottom: 6rem
    }

    .placeholder-gray-500::placeholder {
        --placeholder-opacity: 1;
        color: #a0aec0;
        color: rgba(160, 174, 192, var(--placeholder-opacity))
    }

    .pointer-events-none {
        pointer-events: none
    }

    .pointer-events-auto {
        pointer-events: auto
    }

    .static {
        position: static
    }

    .fixed {
        position: fixed
    }

    .absolute {
        position: absolute
    }

    .relative {
        position: relative
    }

    .top-0 {
        top: 0
    }

    .right-0 {
        right: 0
    }

    .bottom-0 {
        bottom: 0
    }

    .left-0 {
        left: 0
    }

    .resize {
        resize: both
    }

    .shadow-md {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06)
    }

    .text-left {
        text-align: left
    }

    .text-center {
        text-align: center
    }

    .text-right {
        text-align: right
    }

    .text-white {
        --text-opacity: 1;
        color: #fff;
        color: rgba(255, 255, 255, var(--text-opacity))
    }

    .text-gray-500 {
        --text-opacity: 1;
        color: #a0aec0;
        color: rgba(160, 174, 192, var(--text-opacity))
    }

    .text-gray-600 {
        --text-opacity: 1;
        color: #718096;
        color: rgba(113, 128, 150, var(--text-opacity))
    }

    .text-gray-700 {
        --text-opacity: 1;
        color: #4a5568;
        color: rgba(74, 85, 104, var(--text-opacity))
    }

    .text-red-600 {
        --text-opacity: 1;
        color: #e53e3e;
        color: rgba(229, 62, 62, var(--text-opacity))
    }

    .text-bryton-purple-500 {
        --text-opacity: 1;
        color: #644a99;
        color: rgba(100, 74, 153, var(--text-opacity))
    }

    .text-bryton-red-500 {
        --text-opacity: 1;
        color: #e83d3d;
        color: rgba(232, 61, 61, var(--text-opacity))
    }

    .text-bryton-yellow-500 {
        --text-opacity: 1;
        color: #f7c522;
        color: rgba(247, 197, 34, var(--text-opacity))
    }

    .text-bryton-green-500 {
        --text-opacity: 1;
        color: #1e9438;
        color: rgba(30, 148, 56, var(--text-opacity))
    }

    .uppercase {
        text-transform: uppercase
    }

    .lowercase {
        text-transform: lowercase
    }

    .capitalize {
        text-transform: capitalize
    }

    .tracking-wide {
        letter-spacing: .025em
    }

    .tracking-widest {
        letter-spacing: .1em
    }

    .whitespace-normal {
        white-space: normal
    }

    .whitespace-no-wrap {
        white-space: nowrap
    }

    .w-4 {
        width: 1rem
    }

    .w-6 {
        width: 1.5rem
    }

    .w-12 {
        width: 3rem
    }

    .w-64 {
        width: 16rem
    }

    .w-auto {
        width: auto
    }

    .w-8\/12 {
        width: 66.666667%
    }

    .w-10\/12 {
        width: 83.333333%
    }

    .w-full {
        width: 100%
    }

    .w-screen {
        width: 100vw
    }

    .w-max-content {
        width: max-content
    }

    .w-min-content {
        width: min-content
    }

    .z-0 {
        z-index: 0
    }

    .z-10 {
        z-index: 10
    }

    .z-20 {
        z-index: 20
    }

    .gap-x-3 {
        grid-column-gap: .75rem;
        column-gap: .75rem
    }

    .gap-y-1 {
        grid-row-gap: .25rem;
        row-gap: .25rem
    }

    .grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .col-span-1 {
        grid-column: span 1/span 1
    }

    .col-span-2 {
        grid-column: span 2/span 2
    }

    .col-span-3 {
        grid-column: span 3/span 3
    }

    .col-span-4 {
        grid-column: span 4/span 4
    }

    .col-span-5 {
        grid-column: span 5/span 5
    }

    .col-span-6 {
        grid-column: span 6/span 6
    }

    .col-span-full {
        grid-column: 1/-1
    }

    .grid-rows-2 {
        grid-template-rows: repeat(2, minmax(0, 1fr))
    }

    .row-span-1 {
        grid-row: span 1/span 1
    }

    .transform {
        --transform-translate-x: 0;
        --transform-translate-y: 0;
        --transform-rotate: 0;
        --transform-skew-x: 0;
        --transform-skew-y: 0;
        --transform-scale-x: 1;
        --transform-scale-y: 1;
        transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
    }

    .transition-all {
        transition-property: all
    }

    .transition {
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
    }

    .transition-transform {
        transition-property: transform
    }

    @keyframes spin {
        to {
            transform: rotate(360deg)
        }
    }

    @keyframes ping {

        100%, 75% {
            transform: scale(2);
            opacity: 0
        }
    }

    @keyframes pulse {
        50% {
            opacity: .5
        }
    }

    @keyframes bounce {

        0%, 100% {
            transform: translateY(-25%);
            animation-timing-function: cubic-bezier(.8, 0, 1, 1)
        }

        50% {
            transform: none;
            animation-timing-function: cubic-bezier(0, 0, .2, 1)
        }
    }

    .word-tracking-tight {
        word-spacing: -.025em
    }

    .word-tracking-tightest {
        word-spacing: -.075em
    }

    .aspect-16-9 {
        height: 0;
        width: 100%;
        padding-bottom: 56.25%
    }

    @media (min-width:640px) {
        .tablet\:border-gray-300 {
            --border-opacity: 1;
            border-color: #e2e8f0;
            border-color: rgba(226, 232, 240, var(--border-opacity))
        }

        .tablet\:rounded-md {
            border-radius: .375rem
        }

        .tablet\:rounded-full {
            border-radius: 9999px
        }

        .tablet\:border-dashed {
            border-style: dashed
        }

        .tablet\:block {
            display: block
        }

        .tablet\:flex {
            display: flex
        }

        .tablet\:hidden {
            display: none
        }

        .tablet\:flex-row {
            flex-direction: row
        }

        .tablet\:flex-col {
            flex-direction: column
        }

        .tablet\:flex-wrap {
            flex-wrap: wrap
        }

        .tablet\:items-center {
            align-items: center
        }

        .tablet\:content-center {
            align-content: center
        }

        .tablet\:justify-center {
            justify-content: center
        }

        .tablet\:h-8 {
            height: 2rem
        }

        .tablet\:h-48 {
            height: 12rem
        }

        .tablet\:h-auto {
            height: auto
        }

        .tablet\:text-xs {
            font-size: .75rem
        }

        .tablet\:text-base {
            font-size: 1rem
        }

        .tablet\:text-xl {
            font-size: 1.25rem
        }

        .tablet\:text-2xl {
            font-size: 1.5rem
        }

        .tablet\:text-3xl {
            font-size: 1.875rem
        }

        .tablet\:text-4xl {
            font-size: 2.25rem
        }

        .tablet\:text-6xl {
            font-size: 3.7rem
        }

        .tablet\:leading-normal {
            line-height: 1.5
        }

        .tablet\:leading-loose {
            line-height: 2
        }

        .tablet\:mx-0 {
            margin-left: 0;
            margin-right: 0
        }

        .tablet\:my-12 {
            margin-top: 3rem;
            margin-bottom: 3rem
        }

        .tablet\:my-32 {
            margin-top: 8rem;
            margin-bottom: 8rem
        }

        .tablet\:mx-auto {
            margin-left: auto;
            margin-right: auto
        }

        .tablet\:mt-0 {
            margin-top: 0
        }

        .tablet\:mb-0 {
            margin-bottom: 0
        }

        .tablet\:ml-4 {
            margin-left: 1rem
        }

        .tablet\:mt-6 {
            margin-top: 1.5rem
        }

        .tablet\:mr-6 {
            margin-right: 1.5rem
        }

        .tablet\:mt-8 {
            margin-top: 2rem
        }

        .tablet\:mb-8 {
            margin-bottom: 2rem
        }

        .tablet\:mb-12 {
            margin-bottom: 3rem
        }

        .tablet\:mb-16 {
            margin-bottom: 4rem
        }

        .tablet\:ml-16 {
            margin-left: 4rem
        }

        .tablet\:mt-32 {
            margin-top: 8rem
        }

        .tablet\:p-0 {
            padding: 0
        }

        .tablet\:p-2 {
            padding: .5rem
        }

        .tablet\:px-0 {
            padding-left: 0;
            padding-right: 0
        }

        .tablet\:py-2 {
            padding-top: .5rem;
            padding-bottom: .5rem
        }

        .tablet\:px-3 {
            padding-left: .75rem;
            padding-right: .75rem
        }

        .tablet\:px-4 {
            padding-left: 1rem;
            padding-right: 1rem
        }

        .tablet\:py-6 {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem
        }

        .tablet\:py-8 {
            padding-top: 2rem;
            padding-bottom: 2rem
        }

        .tablet\:px-8 {
            padding-left: 2rem;
            padding-right: 2rem
        }

        .tablet\:px-12 {
            padding-left: 3rem;
            padding-right: 3rem
        }

        .tablet\:px-20 {
            padding-left: 5rem;
            padding-right: 5rem
        }

        .tablet\:py-24 {
            padding-top: 6rem;
            padding-bottom: 6rem
        }

        .tablet\:pb-0 {
            padding-bottom: 0
        }

        .tablet\:pb-16 {
            padding-bottom: 4rem
        }

        .tablet\:pt-24 {
            padding-top: 6rem
        }

        .tablet\:pt-32 {
            padding-top: 8rem
        }

        .tablet\:absolute {
            position: absolute
        }

        .tablet\:shadow-md {
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06)
        }

        .tablet\:text-left {
            text-align: left
        }

        .tablet\:whitespace-no-wrap {
            white-space: nowrap
        }

        .tablet\:w-8 {
            width: 2rem
        }

        .tablet\:w-32 {
            width: 8rem
        }

        .tablet\:w-4\/5 {
            width: 80%
        }

        .tablet\:w-9\/12 {
            width: 75%
        }

        .tablet\:w-full {
            width: 100%
        }

        .tablet\:gap-x-12 {
            grid-column-gap: 3rem;
            column-gap: 3rem
        }

        .tablet\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr))
        }

        .tablet\:grid-rows-1 {
            grid-template-rows: repeat(1, minmax(0, 1fr))
        }

        .tablet\:translate-x-4 {
            --transform-translate-x: 1rem
        }

        .tablet\:-translate-y-4 {
            --transform-translate-y: -1rem
        }
    }

    @media (min-width:1280px) {
        .desktop\:border-b {
            border-bottom-width: 1px
        }

        .desktop\:block {
            display: block
        }

        .desktop\:grid {
            display: grid
        }

        .desktop\:hidden {
            display: none
        }

        .desktop\:items-start {
            align-items: flex-start
        }

        .desktop\:text-base {
            font-size: 1rem
        }

        .desktop\:text-lg {
            font-size: 1.125rem
        }

        .desktop\:text-2xl {
            font-size: 1.5rem
        }

        .desktop\:text-3xl {
            font-size: 1.875rem
        }

        .desktop\:text-4xl {
            font-size: 2.25rem
        }

        .desktop\:text-6xl {
            font-size: 3.7rem
        }

        .desktop\:my-0 {
            margin-top: 0;
            margin-bottom: 0
        }

        .desktop\:mx-0 {
            margin-left: 0;
            margin-right: 0
        }

        .desktop\:my-24 {
            margin-top: 6rem;
            margin-bottom: 6rem
        }

        .desktop\:mt-0 {
            margin-top: 0
        }

        .desktop\:mb-0 {
            margin-bottom: 0
        }

        .desktop\:mt-8 {
            margin-top: 2rem
        }

        .desktop\:mb-8 {
            margin-bottom: 2rem
        }

        .desktop\:mt-12 {
            margin-top: 3rem
        }

        .desktop\:mb-12 {
            margin-bottom: 3rem
        }

        .desktop\:mb-16 {
            margin-bottom: 4rem
        }

        .desktop\:mr-20 {
            margin-right: 5rem
        }

        .desktop\:mb-32 {
            margin-bottom: 8rem
        }

        .desktop\:mt-40 {
            margin-top: 10rem
        }

        .desktop\:mt-64 {
            margin-top: 16rem
        }

        .desktop\:mb-64 {
            margin-bottom: 16rem
        }

        .desktop\:mt-96 {
            margin-top: 24rem
        }

        .desktop\:overflow-visible {
            overflow: visible
        }

        .desktop\:px-32 {
            padding-left: 8rem;
            padding-right: 8rem
        }

        .desktop\:pb-0 {
            padding-bottom: 0
        }

        .desktop\:pt-8 {
            padding-top: 3rem
        }

        .desktop\:pb-8 {
            padding-bottom: 2rem
        }

        .desktop\:pb-16 {
            padding-bottom: 4rem
        }

        .desktop\:pt-32 {
            padding-top: 8rem
        }

        .desktop\:text-left {
            text-align: left
        }

        .desktop\:whitespace-no-wrap {
            white-space: nowrap
        }

        .desktop\:w-auto {
            width: auto
        }

        .desktop\:w-full {
            width: 100%
        }

        .desktop\:gap-x-8 {
            grid-column-gap: 2rem;
            column-gap: 2rem
        }

        .desktop\:gap-y-2 {
            grid-row-gap: .5rem;
            row-gap: .5rem
        }

        .desktop\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr))
        }

        .desktop\:grid-cols-7 {
            grid-template-columns: repeat(7, minmax(0, 1fr))
        }

        .desktop\:grid-rows-1 {
            grid-template-rows: repeat(1, minmax(0, 1fr))
        }
    }

    code[class*=language-],
    pre[class*=language-] {
        color: #000;
        background: 0 0;
        text-shadow: 0 1px #fff;
        font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        font-size: 1em;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;
        -moz-tab-size: 4;
        -o-tab-size: 4;
        tab-size: 4;
        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none
    }

        code[class*=language-] ::-moz-selection,
        code[class*=language-]::-moz-selection,
        pre[class*=language-] ::-moz-selection,
        pre[class*=language-]::-moz-selection {
            text-shadow: none;
            background: #b3d4fc
        }

        code[class*=language-] ::selection,
        code[class*=language-]::selection,
        pre[class*=language-] ::selection,
        pre[class*=language-]::selection {
            text-shadow: none;
            background: #b3d4fc
        }

    @media print {

        code[class*=language-],
        pre[class*=language-] {
            text-shadow: none
        }
    }

    pre[class*=language-] {
        padding: 1em;
        margin: .5em 0;
        overflow: auto
    }

    :not(pre) > code[class*=language-],
    pre[class*=language-] {
        background: #f5f2f0
    }

    :not(pre) > code[class*=language-] {
        padding: .1em;
        border-radius: .3em;
        white-space: normal
    }

    .token.cdata,
    .token.comment,
    .token.doctype,
    .token.prolog {
        color: #708090
    }

    .token.punctuation {
        color: #999
    }

    .token.namespace {
        opacity: .7
    }

    .token.boolean,
    .token.constant,
    .token.deleted,
    .token.number,
    .token.property,
    .token.symbol,
    .token.tag {
        color: #905
    }

    .token.attr-name,
    .token.builtin,
    .token.char,
    .token.inserted,
    .token.selector,
    .token.string {
        color: #690
    }

    .language-css .token.string,
    .style .token.string,
    .token.entity,
    .token.operator,
    .token.url {
        color: #9a6e3a;
        background: hsla(0, 0%, 100%, .5)
    }

    .token.atrule,
    .token.attr-value,
    .token.keyword {
        color: #07a
    }

    .token.class-name,
    .token.function {
        color: #dd4a68
    }

    .token.important,
    .token.regex,
    .token.variable {
        color: #e90
    }

    .token.bold,
    .token.important {
        font-weight: 700
    }

    .token.italic {
        font-style: italic
    }

    .token.entity {
        cursor: help
    }

    .nuxt-progress {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
        width: 0%;
        opacity: 1;
        transition: width .1s, opacity .4s;
        background-color: #000;
        z-index: 999999
    }

        .nuxt-progress.nuxt-progress-notransition {
            transition: none
        }

    .nuxt-progress-failed {
        background-color: red
    }

    :root {
        --bryton-purple-500: #644a99;
        --bryton-magenta-500: #b53480;
        --bryton-red-500: #e83d3d;
        --bryton-yellow-500: #f7c522;
        --bryton-green-500: #1e9438;
        --bryton-blue-500: #009fe3;
        --bryton-cyan-500: #58cdce;
        --bryton-grey-500: #e8e8e8
    }

    html {
        font-family: Montserrat, sans-serif;
        word-spacing: 1px;
        -ms-text-size-adjust: none;
        -webkit-text-size-adjust: none;
        text-size-adjust: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
        min-width: 320px;
        font-size: .8125em;
        scroll-behavior: smooth;
        --list-separator-color: white;
        --container-padding-x: 2rem
    }

    @media (min-width:640px) {
        html {
            font-size: .875em
        }
    }

    @media (min-width:1280px) {
        html {
            --container-padding-x: 4rem
        }
    }

    ul {
        list-style: inside square
    }

    .alternate-list ul li {
        list-style: none
    }

        .alternate-list ul li::after {
            content: '';
            display: block;
            height: 2px;
            width: 40px;
            background-color: var(--list-separator-color);
            margin-left: auto;
            margin-right: auto;
            margin-top: 1rem;
            margin-bottom: 1rem
        }

    @media (min-width:640px) {
        .alternate-list ul li::after {
            width: 70px;
            margin-top: 1.25rem;
            margin-bottom: 1.25rem
        }
    }

    .alternate-list ul li:last-child::after {
        display: none
    }

    @media (min-width:1280px) {
        .alternate-list.alternate-list--no-desktop ul li {
            list-style: inside square
        }

            .alternate-list.alternate-list--no-desktop ul li::after {
                display: none
            }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600
    }

        h1 strong,
        h2 strong,
        h3 strong,
        h4 strong,
        h5 strong,
        h6 strong {
            font-weight: 900
        }

    p {
        margin-bottom: 1rem
    }

        p:last-child {
            margin-bottom: 0
        }

    *,
    ::after,
    ::before {
        box-sizing: border-box;
        margin: 0
    }

    .page-container {
        width: 72%;
        min-width: 320px;
        margin: 0 auto
    }

    @media (min-width:640px) {
        .page-container {
            min-width: 640px
        }
    }

    @media (min-width:1280px) {
        .page-container {
            width: 60%;
            min-width: 1150px
        }
    }

    .page-container.page-container--padded {
        padding-right: var(--container-padding-x);
        padding-left: var(--container-padding-x)
    }

    .bolding-link {
        outline: 2px solid transparent;
        outline-offset: 2px
    }

        .bolding-link:focus,
        .bolding-link:hover {
            --text-shadow-radius: 0;
            --text-shadow-color: black;
            text-shadow: 0 0 var(--text-shadow-radius) var(--text-shadow-color), 0 0 var(--text-shadow-radius) var(--text-shadow-color)
        }

    .bolding-link {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    @media (min-width:640px) {
        .bolding-link {
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: .5rem;
            padding-right: .5rem
        }
    }

    @media (min-width:1280px) {
        .bolding-link {
            padding-left: 1rem;
            padding-right: 1rem
        }
    }

    .easter-egg {
        transition: all .2s ease-in-out;
        height: max-content;
        top: calc(8.5rem + 154px);
        left: 20%;
        font-size: .5rem;
        transform-origin: top;
        writing-mode: vertical-lr;
        -webkit-text-orientation: upright;
        text-orientation: upright;
        letter-spacing: 7.57118px;
        position: fixed;
        padding-left: 1rem;
        padding-right: 1rem;
        text-transform: uppercase;
        box-sizing: content-box;
        overflow: visible;
        --text-opacity: 1;
        color: #a0aec0;
        color: rgba(160, 174, 192, var(--text-opacity));
        font-weight: 600
    }

        .easter-egg:hover {
            letter-spacing: 12.6186px;
            --text-opacity: 1;
            color: #000;
            color: rgba(0, 0, 0, var(--text-opacity))
        }

    .easter-egg__channel {
        transform: scale(1);
        position: fixed;
        height: 100%;
        z-index: 10;
        right: 0
    }

    .banner__spacer[data-v-196c9fc8] {
        width: 100%;
        height: calc(7.6rem + 4px)
    }

    @media (min-width:1280px) {
        .banner__spacer[data-v-196c9fc8] {
            height: calc(8.5rem + 4px)
        }
    }

    .banner__logo[data-v-196c9fc8] {
        height: 2.5rem
    }

    @media (min-width:1280px) {
        .banner__logo[data-v-196c9fc8] {
            height: 3rem
        }
    }

    ul[data-v-def85e5c] {
        list-style: none
    }

    li[data-v-def85e5c] {
        display: flex
    }

    .nav__item[data-v-def85e5c] {
        margin-left: .5rem;
        margin-right: .5rem
    }

    @media (min-width:1280px) {
        .nav__item[data-v-def85e5c] {
            margin-left: 1rem;
            margin-right: 1rem
        }
    }

    .nav__item.nav-menu[data-v-def85e5c] {
        position: relative
    }

        .nav__item.nav-menu[data-v-def85e5c] .nav-menu__button {
            font-weight: 600;
            outline: 2px solid transparent;
            outline-offset: 2px
        }

        .nav__item.nav-menu[data-v-def85e5c] .nav-menu__list {
            top: 100%;
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-row-gap: .25rem;
            row-gap: .25rem;
            position: absolute;
            width: 100%;
            padding-top: .5rem;
            list-style-type: none
        }

        .nav__item.nav-menu[data-v-def85e5c] .nav-menu__item {
            width: 100%
        }

        .nav__item.nav-menu[data-v-def85e5c] .nav-menu__link {
            background-color: #ffffffbb;
            display: block;
            padding-left: .75rem;
            padding-top: .25rem;
            padding-bottom: .25rem;
            font-size: .875rem;
            font-weight: 400
        }

    .mobile-menu[data-v-fa7d4fa4] {
        pointer-events: none;
        z-index: 20;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }

    .mobile-menu__background[data-v-fa7d4fa4] {
        pointer-events: auto;
        --bg-opacity: 1;
        background-color: #f7c522;
        background-color: rgba(247, 197, 34, var(--bg-opacity))
    }

    .mobile-menu__button[data-v-fa7d4fa4] {
        fill: #fff;
        outline: 0;
        padding: 0 0 16px 16px;
        transform: translate(100% -100%);
        pointer-events: auto;
        --bg-opacity: 1;
        background-color: #644a99;
        background-color: rgba(100, 74, 153, var(--bg-opacity))
    }

    .mobile-menu .mobile-menu__background[data-v-fa7d4fa4],
    .mobile-menu__button[data-v-fa7d4fa4] {
        top: 0;
        right: 0;
        min-width: 60px;
        min-height: 60px;
        height: 15vw;
        width: 15vw;
        max-width: 70px;
        max-height: 70px;
        transition-duration: .15s;
        transition-timing-function: ease-in-out;
        transition-property: transform;
        position: absolute;
        border-bottom-left-radius: 9999px
    }

    .mobile-menu__menu[data-v-fa7d4fa4] {
        margin-top: 60px;
        pointer-events: auto;
        width: 83.333333%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.125rem;
        font-weight: 600
    }

    .mobile-menu__nav-item[data-v-fa7d4fa4] {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #00000022;
        box-sizing: border-box
    }

        .mobile-menu__nav-item[data-v-fa7d4fa4]:first-child {
            border-top: 1px solid #00000022
        }

    .mobile-menu__nav-item.nav-menu[data-v-fa7d4fa4] {
        overflow: hidden;
        border-bottom: 0
    }

    .mobile-menu__nav-item.nav-menu .nav-menu__list[data-v-fa7d4fa4] {
        width: 100%;
        list-style-type: none
    }

    .mobile-menu__nav-item.nav-menu .nav-menu__item[data-v-fa7d4fa4] {
        padding-left: 2rem;
        display: flex;
        width: 100%
    }

        .mobile-menu__nav-item.nav-menu .nav-menu__item[data-v-fa7d4fa4]:last-child {
            border-bottom: 1px solid #00000022
        }

    .mobile-menu__nav-item.nav-menu .nav-menu__link[data-v-fa7d4fa4] {
        display: inline-block;
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .mobile-menu .nav-menu__button[data-v-fa7d4fa4] {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;
        font-size: 1.125rem;
        font-weight: 600;
        outline: 2px solid transparent;
        outline-offset: 2px
    }

    .mobile-menu .nav-menu__icon[data-v-fa7d4fa4] {
        height: 2rem
    }

    .mobile-menu--is-active .mobile-menu__background[data-v-fa7d4fa4] {
        transform: scale(100)
    }

    .mobile-menu--is-active .mobile-menu__button[data-v-fa7d4fa4] {
        fill: #000;
        background-color: transparent
    }

    .list-move[data-v-fa7d4fa4] {
        transition: all 1s
    }

    .list-move.nav-menu[data-v-fa7d4fa4] {
        transition: unset
    }

    .list-leave-active[data-v-fa7d4fa4] {
        position: absolute;
        transition: all 1s
    }

    .fade-enter-active[data-v-fa7d4fa4],
    .fade-leave-active[data-v-fa7d4fa4] {
        transition: all 1s
    }

    .fade-enter[data-v-fa7d4fa4],
    .fade-leave-to[data-v-fa7d4fa4] {
        opacity: 0;
        transform: translateY(-100%)
    }

    .home-page__container[data-v-698818eb] {
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .home-page__heading[data-v-698818eb] {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        letter-spacing: .025em;
        text-align: center
    }

    @media (min-width:640px) {
        .home-page__heading[data-v-698818eb] {
            font-size: 2.7rem
        }
    }

    @media (min-width:1280px) {
        .home-page__heading[data-v-698818eb] {
            font-size: 4.9rem;
            margin-bottom: 4rem;
            line-height: 1
        }
    }

    .home-page__heading.home-page__heading--large[data-v-698818eb] {
        font-size: 2.25rem
    }

    @media (min-width:640px) {
        .home-page__heading.home-page__heading--large[data-v-698818eb] {
            font-size: 3.7rem
        }
    }

    @media (min-width:1280px) {
        .home-page__heading.home-page__heading--large[data-v-698818eb] {
            font-size: 4.9rem
        }
    }

    .home-page__content[data-v-698818eb] {
        max-width: 740px;
        font-size: 1.25rem;
        line-height: 2;
        margin-bottom: 3rem;
        text-align: center
    }

    @media (min-width:640px) {
        .home-page__content[data-v-698818eb] {
            font-size: 1.125rem
        }
    }

    @media (min-width:1280px) {
        .home-page__content[data-v-698818eb] {
            font-size: 1rem
        }

        .home-page__content--wide[data-v-698818eb] {
            line-height: 4;
            width: 100vw;
            max-width: 100vw
        }
    }

    .home-page__content.home-page__content--small-text[data-v-698818eb] {
        font-size: .875rem
    }

    @media (min-width:640px) {
        .home-page__content.home-page__content--small-text[data-v-698818eb] {
            font-size: 1rem
        }
    }

    .splash-region[data-v-71a09f26] {
        width: 100%
    }

    @media (min-width:1280px) {
        .splash-region[data-v-71a09f26] {
            width: auto;
            --splash-min-height: calc(100vh - 128px);
            min-height: var(--splash-min-height)
        }
    }

    @media (min-width:1280px) {
        .hero[data-v-71a09f26] {
            min-height: calc(var(--splash-min-height) * .35)
        }
    }

    .hero__img[data-v-71a09f26] {
        --hero-img-width: var(--default-hero-img-width);
        --hero-img-height: var(--default-hero-img-height);
        width: var(--hero-img-width);
        height: var(--hero-img-height)
    }

    @media (min-width:640px) {
        .hero__img[data-v-71a09f26] {
            --hero-img-width: var(--tablet-hero-img-width);
            --hero-img-height: var(--tablet-hero-img-height)
        }
    }

    @media (min-width:1280px) {
        .hero__img[data-v-71a09f26] {
            --hero-img-width: var(--desktop-hero-img-width);
            --hero-img-height: var(--desktop-hero-img-height)
        }
    }

    .hero__commemoration[data-v-71a09f26] {
        --commemoration-radius: var(--default-commemoration-radius);
        display: flex;
        --text-opacity: 1;
        color: #fff;
        color: rgba(255, 255, 255, var(--text-opacity));
        flex-direction: column;
        align-items: center;
        justify-content: center;
        --bg-opacity: 1;
        background-color: #644a99;
        background-color: rgba(100, 74, 153, var(--bg-opacity));
        border-radius: 9999px;
        line-height: 1.15;
        --size: calc(2 * var(--commemoration-radius));
        --size: 112px;
        width: var(--size);
        height: var(--size)
    }

    @media (min-width:640px) {
        .hero__commemoration[data-v-71a09f26] {
            --commemoration-radius: var(--tablet-commemoration-radius)
        }
    }

    @media (min-width:1280px) {
        .hero__commemoration[data-v-71a09f26] {
            --commemoration-radius: var(--desktop-commemoration-radius)
        }
    }

    @media (min-width:640px) {
        .hero__commemoration[data-v-71a09f26] {
            --size: 150px
        }
    }

    .hero__commemoration div[data-v-71a09f26] {
        width: max-content
    }

    @media (min-width:1280px) {
        .services[data-v-71a09f26] {
            min-height: calc(var(--splash-min-height) * .57)
        }
    }

    .services__col[data-v-71a09f26] {
        display: flex;
        flex-direction: column;
        justify-content: space-around
    }

    @media (min-width:640px) {
        .services__col[data-v-71a09f26] {
            margin-left: 2rem;
            margin-right: 2rem
        }
    }

    @media (min-width:1280px) {
        .services__col[data-v-71a09f26] {
            width: 600px;
            margin-left: 0;
            margin-right: 0
        }
    }

    .services__col--left[data-v-71a09f26] {
        padding-top: 100px
    }

    .services__col--right[data-v-71a09f26] {
        padding-bottom: 100px
    }

    .services__service[data-v-71a09f26] {
        margin-bottom: 2rem
    }

        .services__service[data-v-71a09f26]:last-child {
            margin-bottom: 0
        }

    .quote-anchor[data-v-71a09f26] {
        background-color: #ffffff99;
        border-top-right-radius: .5rem;
        border-top-left-radius: .5rem;
        padding: .8rem 1rem;
        transition: transform .2s ease-out
    }

    .quote-anchor__container[data-v-71a09f26] {
        display: flex
    }

    .quote-anchor__container--fixed[data-v-71a09f26] {
        position: fixed;
        z-index: 10;
        bottom: 0
    }

    .quote-anchor__container--fixed .quote-anchor[data-v-71a09f26] {
        position: relative;
        padding-bottom: 1.6rem;
        bottom: -.8rem
    }

    .quote-anchor__container--fixed .quote-anchor--lower[data-v-71a09f26] {
        transform: translateY(.6rem)
    }

    .quote-anchor__container--fixed .quote-anchor--higher[data-v-71a09f26] {
        transform: translateY(-.6rem)
    }

    .angled-strip[data-v-7b187159] {
        --scale-factor: var(--default-scale-factor);
        --margin-y: var(--default-margin-y);
        --transform-origin-x: var(--default-transform-origin-x);
        --top-transform-origin-x: var(--default-top-transform-origin-x);
        --bg-angle: calc(-1 * var(--angle));
        --bg-accent-angle: var(--angle);
        margin-top: var(--margin-y);
        margin-bottom: var(--margin-y);
        width: 100%;
        overflow: visible;
        position: relative
    }

    @media (min-width:640px) {
        .angled-strip[data-v-7b187159] {
            --margin-y: var(--tablet-margin-y)
        }
    }

    @media (min-width:1280px) {
        .angled-strip[data-v-7b187159] {
            --scale-factor: var(--desktop-scale-factor);
            --margin-y: var(--desktop-margin-y);
            --transform-origin-x: var(--desktop-transform-origin-x);
            --top-transform-origin-x: var(--desktop-top-transform-origin-x)
        }
    }

    .angled-strip__background[data-v-7b187159] {
        content: '';
        background-color: var(--color);
        z-index: -1;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0
    }

    .angled-strip--top[data-v-7b187159] {
        margin-top: 0
    }

    .angled-strip--top .angled-strip__background[data-v-7b187159] {
        transform-origin: var(--top-transform-origin-x) bottom;
        transform: rotate(var(--bg-angle)) scaleY(10) translateX(200%) scaleX(10)
    }

    .angled-strip--middle .angled-strip__background[data-v-7b187159] {
        transform-origin: var(--transform-origin-x) top;
        transform: rotate(var(--bg-angle)) scaleY(var(--scale-factor)) translateX(-200%) scaleX(10)
    }

    .angled-strip--bottom[data-v-7b187159] {
        margin-bottom: 0
    }

    .angled-strip--bottom .angled-strip__background[data-v-7b187159] {
        transform-origin: var(--transform-origin-x) top;
        transform: rotate(var(--bg-angle)) scaleY(10) translateX(-200%) scaleX(10)
    }

    .angled-strip.angled-strip--accented .angled-strip__accent[data-v-7b187159] {
        content: '';
        background-color: var(--accent-color);
        z-index: -2;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0
    }

    .angled-strip.angled-strip--accented.angled-strip--top .angled-strip__accent[data-v-7b187159] {
        transform-origin: right bottom;
        transform: rotate(var(--bg-accent-angle)) scaleY(10) translateX(200%) scaleX(10)
    }

    .angled-strip.angled-strip--accented.angled-strip--middle .angled-strip__accent[data-v-7b187159] {
        transform-origin: left top;
        transform: rotate(var(--bg-accent-angle)) scaleY(var(--accent-scale-factor)) translateX(-200%) scaleX(10)
    }

    .angled-strip.angled-strip--accented.angled-strip--bottom .angled-strip__accent[data-v-7b187159] {
        transform-origin: left top;
        transform: rotate(var(--bg-accent-angle)) scaleY(10) translateX(-200%) scaleX(10)
    }

    .flair[data-v-7b187159] {
        --flair-bottom: var(--default-flair-bottom);
        --flair-margin-bottom: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        bottom: var(--flair-bottom);
        margin-bottom: var(--flair-margin-bottom)
    }

    @media (min-width:640px) {
        .flair[data-v-7b187159] {
            --flair-bottom: var(--tablet-flair-bottom)
        }
    }

    @media (min-width:1280px) {
        .flair[data-v-7b187159] {
            --flair-bottom: var(--desktop-flair-bottom);
            --flair-margin-bottom: var(--desktop-flair-margin-bottom)
        }
    }

    @media (min-width:1280px) {
        .flair[data-v-7b187159] {
            width: auto;
            right: 0
        }
    }

    .callout[data-v-8e38860e] {
        width: max-content;
        height: min-content;
        color: var(--text-color)
    }

        .callout[data-v-8e38860e]:hover {
            color: var(--reactive-text-color)
        }

        .callout[data-v-8e38860e]:focus {
            color: var(--reactive-text-color)
        }

    .animated-underline[data-v-52d451c5] {
        width: max-content;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        overflow: hidden;
        --underline-width: var(--default-underline-width);
        --underline-alignment: var(--default-underline-alignment);
        --alignment: var(--default-alignment)
    }

    @media (min-width:640px) {
        .animated-underline[data-v-52d451c5] {
            --underline-width: var(--tablet-underline-width);
            --underline-alignment: var(--tablet-underline-alignment);
            --alignment: var(--tablet-alignment)
        }
    }

    @media (min-width:1280px) {
        .animated-underline[data-v-52d451c5] {
            --underline-width: var(--desktop-underline-width);
            --underline-alignment: var(--desktop-underline-alignment);
            --alignment: var(--desktop-alignment)
        }
    }

    .animated-underline[data-v-52d451c5]::after {
        content: '';
        background-color: var(--underline-color);
        transition: transform .1s ease-in-out;
        height: 2px;
        width: var(--underline-width);
        min-width: 30px;
        transform-origin: var(--underline-alignment);
        align-self: var(--alignment)
    }

    .animated-underline--active[data-v-52d451c5]::after {
        transform: scaleX(10)
    }

    .service-container[data-v-34ee350c] {
        --flex-direction: var(--default-flex-direction);
        --text-alignment: var(--default-text-alignment);
        --flex-alignment: var(--default-flex-alignment);
        --auto-length: var(--default-auto-length);
        display: flex;
        flex-direction: var(--flex-direction);
        height: var(--auto-length, var(--service-container-height));
        cursor: pointer
    }

    @media (min-width:640px) {
        .service-container[data-v-34ee350c] {
            --flex-direction: var(--tablet-flex-direction);
            --text-alignment: var(--tablet-text-alignment);
            --flex-alignment: var(--tablet-flex-alignment);
            --auto-length: var(--tablet-auto-length)
        }
    }

    @media (min-width:1280px) {
        .service-container[data-v-34ee350c] {
            --flex-direction: var(--desktop-flex-direction);
            --text-alignment: var(--desktop-text-alignment);
            --flex-alignment: var(--desktop-flex-alignment);
            --auto-length: var(--desktop-auto-length)
        }
    }

    .service-container img[data-v-34ee350c] {
        height: var(--auto-length, var(--service-img-height));
        z-index: -1;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto
    }

    .service-container.service-container--center[data-v-34ee350c] {
        max-width: var(--img-width);
        width: 100%;
        height: auto
    }

    .service-container.service-container--center img[data-v-34ee350c] {
        width: 100%;
        height: auto;
        margin-bottom: .5rem
    }

    .service-container .copy-box[data-v-34ee350c] {
        text-align: var(--text-alignment);
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        align-self: center
    }

    .service-container .copy-box__header[data-v-34ee350c] {
        align-self: var(--flex-alignment);
        margin-bottom: 1.5rem
    }

    .service-container .copy-box__header h2[data-v-34ee350c] {
        font-size: 1.25rem;
        font-weight: 900
    }

    @media (min-width:640px) {
        .service-container .copy-box[data-v-34ee350c] {
            max-width: 300px;
            font-size: 1rem
        }

        .service-container .copy-box__copy[data-v-34ee350c] {
            line-height: 1.375
        }

        .service-container .copy-box__header h2[data-v-34ee350c] {
            margin-bottom: .5rem
        }
    }

    @media (min-width:640px) {
        .img-container[data-v-34ee350c] {
            max-width: 300px;
            height: max-content;
            align-self: center
        }
    }

    @media (min-width:1280px) {
        .img-container[data-v-34ee350c] {
            width: 300px
        }
    }

    .testimonial__content[data-v-70148a70] {
        height: 400px
    }

        .testimonial__content[data-v-70148a70]::before {
            content: '\201C';
            top: -.6em;
            left: -16px;
            font-size: 54pt;
            position: absolute;
            line-height: 1.5;
            --text-opacity: 1;
            color: #f7c522;
            color: rgba(247, 197, 34, var(--text-opacity));
            font-weight: 900
        }

    @media (min-width:640px) {
        .testimonial__content[data-v-70148a70]::before {
            top: -.52em;
            left: -18px;
            font-size: 77pt
        }
    }

    @media (min-width:1280px) {
        .testimonial__content[data-v-70148a70]::before {
            top: -.5em;
            left: -20px;
            font-size: 100pt
        }
    }

    .testimonial__content[data-v-70148a70]::after {
        content: '\201D';
        bottom: -.95em;
        right: -17px;
        font-size: 54pt;
        position: absolute;
        line-height: 1.5;
        --text-opacity: 1;
        color: #f7c522;
        color: rgba(247, 197, 34, var(--text-opacity));
        font-weight: 900
    }

    @media (min-width:640px) {
        .testimonial__content[data-v-70148a70]::after {
            bottom: -.88em;
            right: -21px;
            font-size: 77pt
        }
    }

    @media (min-width:1280px) {
        .testimonial__content[data-v-70148a70]::after {
            bottom: -.87em;
            right: -27px;
            font-size: 100pt
        }
    }

    .testimonial__controller[data-v-70148a70] {
        width: min-content;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row
    }

    .testimonial__controller.testimonial__controller--mobile[data-v-70148a70] {
        display: flex
    }

    @media (min-width:640px) {
        .testimonial__controller.testimonial__controller--mobile[data-v-70148a70] {
            display: none
        }
    }

    .testimonial__controller.testimonial__controller--tablet[data-v-70148a70] {
        display: none
    }

    @media (min-width:640px) {
        .testimonial__controller.testimonial__controller--tablet[data-v-70148a70] {
            display: flex
        }
    }

    .testimonial__controller-increment[data-v-70148a70] {
        width: 22px;
        height: 24px;
        --path: polygon(0 0, 100% 50%, 0 100%);
        clip-path: var(--path);
        -webkit-clip-path: var(--path);
        --bg-opacity: 1;
        background-color: #fff;
        background-color: rgba(255, 255, 255, var(--bg-opacity));
        margin-left: 1rem;
        margin-right: 1rem
    }

        .testimonial__controller-increment[data-v-70148a70]:focus {
            --bg-opacity: 1;
            background-color: #f7c522;
            background-color: rgba(247, 197, 34, var(--bg-opacity))
        }

    .testimonial__controller-decrement[data-v-70148a70] {
        width: 22px;
        height: 24px;
        --path: polygon(100% 0, 100% 100%, 0 50%);
        clip-path: var(--path);
        -webkit-clip-path: var(--path);
        --bg-opacity: 1;
        background-color: #fff;
        background-color: rgba(255, 255, 255, var(--bg-opacity));
        margin-left: 1rem;
        margin-right: 1rem
    }

        .testimonial__controller-decrement[data-v-70148a70]:focus {
            --bg-opacity: 1;
            background-color: #f7c522;
            background-color: rgba(247, 197, 34, var(--bg-opacity))
        }

    .testimonial__controller-button[data-v-70148a70] {
        --bg-opacity: 1;
        background-color: #fff;
        background-color: rgba(255, 255, 255, var(--bg-opacity));
        margin-left: 2rem;
        margin-right: 2rem;
        border-radius: 9999px;
        height: 1rem;
        width: 1rem;
        cursor: pointer
    }

    .testimonial__controller-button--selected[data-v-70148a70] {
        --bg-opacity: 1;
        background-color: #f7c522;
        background-color: rgba(247, 197, 34, var(--bg-opacity))
    }

    .callout[data-v-14785272] {
        width: max-content;
        height: min-content;
        color: var(--text-color)
    }

        .callout[data-v-14785272]:hover {
            color: var(--reactive-text-color)
        }

        .callout[data-v-14785272]:focus {
            color: var(--reactive-text-color)
        }

    .row-gallery[data-v-d457d592] {
        scrollbar-width: none
    }

        .row-gallery[data-v-d457d592]::-webkit-scrollbar {
            display: none
        }

    .row-gallery__item[data-v-d457d592] {
        min-width: max-content
    }

    .row-gallery__img-container[data-v-d457d592] {
        --container-size: 200px;
        width: var(--container-size);
        height: var(--container-size)
    }

    @media (min-width:640px) {
        .row-gallery__img-container[data-v-d457d592] {
            --container-size: 300px
        }
    }

    @media (min-width:1280px) {
        .row-gallery__img-container[data-v-d457d592] {
            --container-size: 360px
        }
    }

    .animate-working[data-v-1dec9371] {
        animation: spin reverse 8s linear infinite
    }

    .contact-form[data-v-1dec9371] {
        width: 100%;
        display: flex;
        flex-direction: column
    }

    .contact-form > [data-v-1dec9371] {
        margin-bottom: 1.5rem
    }

        .contact-form > [data-v-1dec9371]:last-child {
            margin-bottom: 0
        }

    @media (min-width:640px) {
        .contact-form.contact-form--wide[data-v-1dec9371] {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-column-gap: .75rem;
            column-gap: .75rem
        }
    }

    input[type=email][data-v-1dec9371] ::placeholder,
    input[type=text][data-v-1dec9371] ::placeholder,
    textarea[data-v-1dec9371] ::placeholder {
        --text-opacity: 1;
        color: #718096;
        color: rgba(113, 128, 150, var(--text-opacity));
        text-transform: capitalize
    }

    input[type=email][data-v-1dec9371],
    input[type=text][data-v-1dec9371],
    textarea[data-v-1dec9371] {
        border-width: 0;
        --bg-opacity: 1;
        background-color: #cbd5e0;
        background-color: rgba(203, 213, 224, var(--bg-opacity))
    }

        input[type=email][data-v-1dec9371]:focus,
        input[type=email][data-v-1dec9371]:hover,
        input[type=text][data-v-1dec9371]:focus,
        input[type=text][data-v-1dec9371]:hover,
        textarea[data-v-1dec9371]:focus,
        textarea[data-v-1dec9371]:hover {
            border-width: 1px;
            --border-opacity: 1;
            border-color: #000;
            border-color: rgba(0, 0, 0, var(--border-opacity))
        }

    .contact-form__field--tall[data-v-1dec9371] {
        min-height: 24rem
    }

    @media (min-width:640px) {
        .contact-form__field--tall[data-v-1dec9371] {
            min-height: 8rem
        }
    }

    .text-input[data-v-8cd163c6]:focus-within {
        --border-opacity: 1;
        border-color: #1a202c;
        border-color: rgba(26, 32, 44, var(--border-opacity))
    }

    .text-input__field[data-v-8cd163c6]::placeholder {
        text-transform: capitalize
    }

    .text-input--required[data-v-8cd163c6] {
        position: relative;
        z-index: 0
    }

        .text-input--required[data-v-8cd163c6]::after {
            content: "*";
            position: absolute;
            display: block;
            right: .5rem;
            top: .2rem;
            --text-opacity: 1;
            color: #e83d3d;
            color: rgba(232, 61, 61, var(--text-opacity))
        }

    .text-area[data-v-082907f1] {
        max-height: min-content
    }

        .text-area[data-v-082907f1]:focus-within {
            --border-opacity: 1;
            border-color: #1a202c;
            border-color: rgba(26, 32, 44, var(--border-opacity))
        }

    .text-area__field[data-v-082907f1] {
        min-height: 8rem
    }

        .text-area__field[data-v-082907f1]::placeholder {
            text-transform: capitalize
        }

    .text-area--required[data-v-082907f1] {
        position: relative;
        z-index: 0
    }

        .text-area--required[data-v-082907f1]::after {
            content: "*";
            position: absolute;
            display: block;
            right: .5rem;
            top: .2rem;
            --text-opacity: 1;
            color: #e83d3d;
            color: rgba(232, 61, 61, var(--text-opacity))
        }

    .footer__container[data-v-17174542] {
        font-size: .7rem
    }

    @media (min-width:640px) {
        .footer__container[data-v-17174542] {
            font-size: .75rem
        }
    }

    @media (min-width:640px) {
        .footer__contact[data-v-17174542] {
            grid-template-columns: max-content max-content
        }
    }

    .footer__link[data-v-17174542] {
        --text-opacity: 1;
        color: #b53480;
        color: rgba(181, 52, 128, var(--text-opacity))
    }

        .footer__link[data-v-17174542]:hover {
            --text-opacity: 1;
            color: #644a99;
            color: rgba(100, 74, 153, var(--text-opacity))
        }

        .footer__link[data-v-17174542]:focus {
            --text-opacity: 1;
            color: #644a99;
            color: rgba(100, 74, 153, var(--text-opacity))
        }

    .footer__header[data-v-17174542] {
        font-weight: 800;
        font-size: 1.25rem;
        margin-bottom: 1rem
    }

    .social-links__link[data-v-17174542] {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: .5rem;
        margin-right: 3rem
    }

    @media (min-width:640px) {
        .social-links__link[data-v-17174542] {
            margin-right: auto
        }

        .testimonial__content[data-v-70148a70] {
            height: 541px !important;
        }

        .hero__inner.w-full {
            width: unset !important;
            margin: auto !important;
            margin-top: 25px !important;
            margin-bottom: 50px !important;
        }
    }

    .social-links__icon[data-v-17174542] {
        display: inline-block;
        --size: 20px;
        height: var(--size);
        width: var(--size)
    }

    @media (min-width:640px) {
        .social-links__icon[data-v-17174542] {
            --size: 24px
        }
    }




    #owl-demo .item img {
        display: block;
        width: 100%;
        height: auto;
    }

    .overflow-y-auto {
        max-height: 312px;
    }

    .owl-theme .owl-controls {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: -63px;
    }

        .owl-theme .owl-controls .owl-page span {
            width: 14px;
            height: 14px;
            margin: 5px 28px;
            opacity: 1;
            background: #fff;
        }

        .owl-theme .owl-controls .owl-page.active span {
            background: #f7c522;
        }

    .owl-wrapper {
        display: flex !important;
        align-items: center
    }

    .testimonial__content[data-v-70148a70] {
        margin-bottom: 150px;
    }

    .captcha {
        display: flex;
        align-items: center;
    }

        .captcha input#txtCaptcha {
            margin-right: 10px;
            padding: 10px;
        }

    .indexcss {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: 0.75rem;
        column-gap: 0.75rem;
        margin-top: 2rem;
    }

        .indexcss > [data-v-1dec9371] {
            margin-bottom: 1.5rem;
        }

    .dots-container {
        display: flex;
        justify-content: center;
        gap: 10px;
        bottom: 113px;
        position: relative;
    }

    .dot.active {
        /* transform: scale(1.5); */
        background-color: #f7c522;
    }

    .dot {
        background-color: #ababab;
    }

    .slider-container.box-border {
        border: none;
        background: none;
    }


    .hero__inner.w-full {
        width: 900px !important;
        margin: auto !important;
        margin-top: 25px !important;
        margin-bottom: 50px !important;
    }

    img.hero__img.hidden.tablet\:block.tablet\:mr-6.desktop\:mr-20 {
        margin-left: 55px;
        margin-right: 14px;
    }

    main.flex.flex-col.items-center.page-container.page-container--padded {
        margin-bottom: 52px;
    }
</style>